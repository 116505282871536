import ConfigHelper from './ConfigHelper';

const {
  ThemeConfig, StaticPages, BasicConfigRawData, Widgets, previewMode, css: customCss,
} = ConfigHelper.getSettings();

// Free memory when not any more needed
delete window.StaticPages;
delete window.BasicConfig;
delete window.BasicConfigProd;
delete window.Widgets;
delete window.ThemeConfig;

export {
  StaticPages, BasicConfigRawData, Widgets, ThemeConfig, previewMode, customCss,
};
export default {
  StaticPages, BasicConfigRawData, Widgets, ThemeConfig, previewMode,
};
