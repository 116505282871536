import {
  StaticPages, Widgets, BasicConfigRawData,
} from './index';

const BasicConfig = {
  ordersToFetch: 5,
  events: {
    cachingEventName: 'newCachingData',
    cartEventName: 'newCartData',
    newServiceWorker: 'newServiceWorker',
  },
  filters: {
    sortingTypes: [
      {
        name: 'bestMatch',
        translation: 'sorting.bestMatch',
        directions: ['desc'],
      },
      {
        name: 'date',
        translation: 'sorting.date',
        directions: ['desc', 'asc'],
      },
      {
        name: 'price',
        translation: 'sorting.price',
        directions: ['asc', 'desc'],
      },
    ],
    perPageValues: [
      24,
      48,
    ],
    viewTypes: [
      {
        name: 'tiles',
        icon: 'view_module',
        translation: 'view.tiles',
      },
      {
        name: 'list',
        icon: 'view_list',
        translation: 'view.list',
      },
    ],
  },
  profile: {
    fieldNames: {
      itemsWeight: 'itemsWeight',
      itemsSum: 'itemsSum',
      productOwnShipping: 'productOwnShipping',
    },
    types: {
      SHIPPING: 'shipping',
      VAT: 'vat',
    },
    statuses: {
      ACTIVE: 'active',
      INACTIVE: 'inactive',
    },
    operatorTypes: {
      AND: 'and',
      OR: 'or',
    },
    conditions: {
      gt: 'gt',
      gte: 'gte',
      lt: 'lt',
      lte: 'lte',
      eq: 'eq',
    },
  },
  widgetsAttr: {
    slider: {
      active: false,
      imageData: [
        {
          original: 'https://i.picsum.photos/id/180/1000/400.jpg?hmac=PIHmrkQhDgnRJY53GxAjpaHBdqsQv2Q_pGo0b2GhH4Q',
          originalAlt: 'slider image 1',
          linkProps: {
            to: '/item/R1C2EFZFXMCD/headphone-cable-storage-case-bag',
          },
        },
        {
          original: 'https://i.picsum.photos/id/961/1000/400.jpg?hmac=s69k8ci1jFkgb5hS4fL2zoIeuQjCgG8U_EaYyK1hAQ4',
          originalAlt: 'slider image 2',
          linkProps: {
            to: '/item/R1C2EFZFXMCD/headphone-cable-storage-case-bag',
          },
        },
        {
          original: 'https://i.picsum.photos/id/743/1000/400.jpg?hmac=zBCCf4poT3HpH2Jw-RkFAE2n2JEVHQ4wovmqzwvImA0',
          originalAlt: 'slider image 3',
          linkProps: {
            to: '/item/R1C2EFZFXMCD/headphone-cable-storage-case-bag',
          },
        },
        {
          original: 'https://i.picsum.photos/id/1016/1000/400.jpg?hmac=nt8K1rs9-cmdrRwfL14D9T2zijviFgmhe6iW7kiWEOc',
          originalAlt: 'slider image 4',
          linkProps: {
            to: '/item/R1C2EFZFXMCD/headphone-cable-storage-case-bag',
          },
        },
      ],
    },
  },
  staticPages: StaticPages,
  widgets: Widgets,
  ...BasicConfigRawData,
};

export { BasicConfigRawData };
export default BasicConfig;
