// Service Worker Helper
import BasicConfig from '../config/BasicConfig';

window.swNeedUpdate = null;
export default class SWHelper {
  static clearAll(cb = () => {}) {
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
          cb();
        });
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    } else {
      console.log('No Service Worker to clear!');
    }
  }

  static isNewAvailable() {
    return window.swNeedUpdate;
  }

  static callNewServiceWorkerEvent() {
    const event = new CustomEvent(
      BasicConfig.events.newServiceWorker,
    );
    window.dispatchEvent(event);
    window.swNeedUpdate = true;
  }
}
