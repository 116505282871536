import React, { lazy, Suspense, useEffect } from 'react';
import { Router } from 'react-router-dom';
import './App.css';
import LoadingSquare from './components/loading/LoadingSquare';
import history from './components/connections/History';

const HelmetContainer = lazy(() => import('./components/containers/HelmetContainer'));

const App = () => {
  useEffect(() => {
    // This element is just to optimize lighthouse, nothing else
    const element = document.getElementById('main-key');
    if (element) {
      element.remove();
    }
  }, []);

  return (
    <Router history={history}>
      <Suspense fallback={<LoadingSquare />}>
        <HelmetContainer />
      </Suspense>
    </Router>
  );
};

export default App;
