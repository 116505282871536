const previewHostnames = process.env.REACT_APP_PREVIEW_HOSTNAMES.split(',');
const previewOriginHosts = process.env.REACT_APP_PREVIEW_ORIGIN_HOSTS.split(',');

const localStorageKey = 'temporaryData';
const environment = process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'prod' : 'dev';

export default class ConfigHelper {
  static activateConfigListener() {
    window.addEventListener('message', ConfigHelper.setupPreviewSettings, false);
  }

  static setupPreviewSettings(event) {
    const originUrl = new URL(event.origin);

    if (
      !previewHostnames.includes(window.location.hostname)
      || !previewOriginHosts.includes(originUrl.host)
    ) {
      return;
    }

    const { action, data } = event.data;
    if (action === 'saveTemporaryData') {
      localStorage.setItem(localStorageKey, JSON.stringify(data));
    } else if (action === 'deleteTemporaryData') {
      localStorage.removeItem(localStorageKey);
    }
  }

  static getSettings() {
    const {
      StaticPages, BasicConfig, BasicConfigProd, Widgets, ThemeConfig,
    } = window;

    const BasicConfigRawData = environment === 'prod'
      ? {
        ...BasicConfig,
        ...BasicConfigProd,
      } : BasicConfig;

    const settingsParameters = {
      StaticPages, BasicConfigRawData, Widgets, ThemeConfig,
    };
    const query = new URLSearchParams(window.location.search);
    const previewParam = query.get('themePreview');

    if (!previewParam) return settingsParameters;
    settingsParameters.previewMode = true;
    ConfigHelper.activateConfigListener();

    try {
      const temporaryData = localStorage.getItem('temporaryData');

      if (!temporaryData) return settingsParameters;
      const {
        theme = settingsParameters.ThemeConfig,
        pages = settingsParameters.StaticPages,
        config = settingsParameters.BasicConfigRawData,
        widgets = settingsParameters.Widgets, css = '',
      } = JSON.parse(temporaryData);

      return {
        ThemeConfig: theme,
        BasicConfigRawData: config,
        StaticPages: pages || settingsParameters.StaticPages,
        Widgets: widgets,
        previewMode: true,
        css,
      };
    } catch (e) {
      return settingsParameters;
    }
  }
}
